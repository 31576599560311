import { Link } from 'react-router-dom'
import { Row, Col, Card, Table } from 'react-bootstrap'
import PageTitle from 'jsx/layouts/PageTitle'
import useFanActionTable from './useFanActionTable'
import { LoadMask } from 'components/common/loadMask'

const FanActionTable = () => {
	const { actions, loading } = useFanActionTable()

	const renderActions = () => {
		return actions.map((action) => {
			return (
				<tr key={`action-${action.id}`}>
					<td>{action.name}</td>
					<td>{action.points} pts</td>
					<td>{action.limit ? `${action.limit} times` : ''}</td>
					<td>{action.limitDurationDays ? `per ${action.limitDurationDays} days` : ''}</td>
					<td>{action.minimalLimit}</td>
					<td>
						<div className="d-flex">
							<Link
								to={`/edit-superfan-action/${action.id}`}
								className="btn btn-primary shadow btn-xs sharp mr-1"
							>
								<i className="fa fa-pencil"></i>
							</Link>
						</div>
					</td>
				</tr>
			)
		})
	}

	return (
		<>
			<PageTitle title={'Superfan Actions'} />
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Superfan Actions</Card.Title>
						</Card.Header>
						<Card.Body>
							{loading ? (
								<LoadMask />
							) : (
								<Table responsive>
									<thead>
										<tr>
											<th>
												<strong>Name</strong>
											</th>
											<th>
												<strong>Points</strong>
											</th>
											<th>
												<strong>Limit</strong>
											</th>
											<th>
												<strong>Limit Duration</strong>
											</th>
											<th>
												<strong>Minimal Limit</strong>
											</th>
										</tr>
									</thead>
									<tbody>{renderActions()}</tbody>
								</Table>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default FanActionTable
