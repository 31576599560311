import MetisMenu from 'metismenujs'
import React, { Component, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

class MM extends Component {
	componentDidMount() {
		this.$el = this.el
		this.mm = new MetisMenu(this.$el)
	}
	componentWillUnmount() {}
	render() {
		return (
			<div className="mm-wrapper">
				<ul className="metismenu" ref={(el) => (this.el = el)}>
					{this.props.children}
				</ul>
			</div>
		)
	}
}

const SideBar = () => {
	const { user: signInUser } = useSelector((state) => state.auth)

	/// Open menu
	useEffect(() => {
		// sidebar open/close
		var btn = document.querySelector('.nav-control')
		var aaa = document.querySelector('#main-wrapper')
		function toggleFunc() {
			return aaa.classList.toggle('menu-toggle')
		}
		btn.addEventListener('click', toggleFunc)
	}, [])
	/// Path
	let path = window.location.pathname
	path = path.split('/')
	path = path[path.length - 1]

	/// Active menu
	let dashBoardFilmio = [''],
		rrs = ['rrs'],
		goScoreBadges = ['goscore-badges'],
		projects = ['projects'],
		perkProgram = ['perk_program'],
		transactions = ['transactions'],
		fanPurchasetransactions = ['fan-purchase-transactions'],
		users = ['users'],
		usersRestricted = ['users-restricted'],
		reviews = ['reviews'],
		newsreels = ['newsreels'],
		comments = ['comments'],
		goScoreStages = ['goscore-stages'],
		studios = ['studios'],
		reports = ['reports'],
		fanLevel = ['superfan-level'],
		fanAction = ['superfan-action'],
		scoreConfig = ['edit-goscore-config'],
		rewardUnlockConfig = ['edit-reward-unlock-config'],
		fanDashboardCarousel = ['fan-dashboard-carousel'],
		daoTier = ['dao-tier'],
		campaign = ['campaigns'],
		contest = ['contests', 'contest/projects/'],
		projectCampaign = ['project-campaigns'],
		tokenSaleCampaignGroup = ['token-sale-campaign-group'],
		diamondCircle = ['diamond-circle'],
		lockedTRS = ['locked-trs'],
		vestingWallets = ['vesting-wallets'],
		ingxRestriction = ['ingx-restriction'],
		troubleshoot = ['troubleshoot'],
		support = ['support'],
		rewardUnlock = ['rewardUnlock'],
		emails = ['email-digest', 'email-triggered', 'create-email-digest'],
		collaboratorRole = ['collaborator-role-table'],
		configuration = ['configuration']

	return (
		<div className="deznav">
			<PerfectScrollbar className="deznav-scroll">
				<div className="main-profile">
					<div className="image-bx">
						<img src={signInUser?.avatar} alt="" />
						<Link to={'#'}>
							<i className="fa fa-cog" aria-hidden="true"></i>
						</Link>
					</div>
					<h5 className="mb-0 fs-20 text-black ">
						<span className="font-w400">Hello,</span> {signInUser?.displayName}
					</h5>
					<p className="mb-0 fs-14 font-w400">{signInUser?.email}</p>
				</div>
				<MM className="metismenu" id="menu">
					<li className="nav-label first">Filmio Admin</li>
					<li
						className={`${dashBoardFilmio.includes(path) || rrs.includes(path) ? 'mm-active' : ''}`}
					>
						<Link className="has-arrow ai-icon" to="/">
							<i className="flaticon-144-layout"></i>
							<span className="nav-text">Dashboard</span>
						</Link>
						<ul>
							<li>
								<Link to="/" className={`${dashBoardFilmio.includes(path) ? 'mm-active' : ''}`}>
									<span className="nav-text">Dashboard</span>
								</Link>
							</li>
							<li>
								<Link to="/rrs" className={`${rrs.includes(path) ? 'mm-active' : ''}`}>
									<span className="nav-text">RRS Dashboard</span>
								</Link>
							</li>
						</ul>
					</li>

					<li className={`${reports.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/reports" className="ai-icon">
							<i className="flaticon-381-calculator"></i>
							<span className="nav-text">Reports</span>
						</Link>
					</li>

					<li className={`${goScoreBadges.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/goscore-badges" className="ai-icon">
							<i className="flaticon-381-book"></i>
							<span className="nav-text">GoScore Badges</span>
						</Link>
					</li>
					<li className={`${goScoreStages.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/goscore-stages" className="ai-icon">
							<i className="flaticon-077-menu-1"></i>
							<span className="nav-text">GoScore Stages</span>
						</Link>
					</li>
					<li className={`${configuration.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/configuration" className="ai-icon">
							<i className="flaticon-381-settings-1"></i>
							<span className="nav-text">Configuration</span>
						</Link>
					</li>
					<li className={`${scoreConfig.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/edit-goscore-config" className="ai-icon">
							<i className="flaticon-381-settings"></i>
							<span className="nav-text">GoScore configuration</span>
						</Link>
					</li>
					<li className={`${rewardUnlockConfig.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/edit-reward-unlock-config" className="ai-icon">
							<i className="flaticon-381-settings-2"></i>
							<span className="nav-text">Reward Unlock Config</span>
						</Link>
					</li>
					<li className={`${fanDashboardCarousel.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/fan-dashboard-carousel" className="ai-icon">
							<i className="flaticon-381-settings-3"></i>
							<span className="nav-text">Fan Dashboard Carousel</span>
						</Link>
					</li>
					<li className={`${projects.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/projects" className="ai-icon">
							<i className="flaticon-003-diamond"></i>
							<span className="nav-text">Projects</span>
						</Link>
					</li>
					<li className={`${perkProgram.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/perk_program" className="ai-icon">
							<i className="flaticon-151-check"></i>
							<span className="nav-text">Filmio Perks Program</span>
						</Link>
					</li>
					<li className={`${transactions.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/transactions" className="ai-icon">
							<i className="flaticon-092-money"></i>
							<span className="nav-text">Transactions</span>
						</Link>
					</li>
					<li className={`${fanPurchasetransactions.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/fan-purchase-transactions" className="ai-icon">
							<i className="flaticon-091-shopping-cart"></i>
							<span className="nav-text">Fan Transactions</span>
						</Link>
					</li>
					<li className={`${collaboratorRole.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/collaborator-roles" className="ai-icon">
							<i className="flaticon-381-controls"></i>
							<span className="nav-text">Collaborator Roles</span>
						</Link>
					</li>
					<li className={`${users.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/users" className="ai-icon">
							<i className="flaticon-381-user"></i>
							<span className="nav-text">Users</span>
						</Link>
					</li>
					<li className={`${usersRestricted.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/users-restricted" className="ai-icon">
							<i className="flaticon-381-user-6"></i>
							<span className="nav-text">Restricted Users</span>
						</Link>
					</li>
					<li className={`${reviews.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/reviews" className="ai-icon">
							<i className="flaticon-381-smartphone-1"></i>
							<span className="nav-text">Reviews</span>
						</Link>
					</li>
					<li className={`${newsreels.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/newsreels" className="ai-icon">
							<i className="flaticon-044-file"></i>
							<span className="nav-text">Newsreels</span>
						</Link>
					</li>
					<li className={`${comments.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/comments" className="ai-icon">
							<i className="flaticon-053-heart"></i>
							<span className="nav-text">Comments</span>
						</Link>
					</li>
					<li className={`${studios.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/studios" className="ai-icon">
							<i className="flaticon-061-puzzle"></i>
							<span className="nav-text">Studios</span>
						</Link>
					</li>
					<li className={`${fanLevel.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/superfan-level" className="ai-icon">
							<i className="flaticon-381-star"></i>
							<span className="nav-text">Superfan Milestones</span>
						</Link>
					</li>
					<li className={`${fanAction.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/superfan-action" className="ai-icon">
							<i className="flaticon-381-speedometer"></i>
							<span className="nav-text">Superfan Actions</span>
						</Link>
					</li>
					<li className={`${daoTier.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/dao-tier" className="ai-icon">
							<i className="flaticon-381-film-strip-1"></i>
							<span className="nav-text">DAO Tiers</span>
						</Link>
					</li>
					<li className={`${campaign.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/campaigns" className="ai-icon">
							<i className="flaticon-381-gift"></i>
							<span className="nav-text">Campaigns</span>
						</Link>
					</li>
					<li className={`${contest.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/contests" className="ai-icon">
							<i className="flaticon-381-menu-2"></i>
							<span className="nav-text">Contests</span>
						</Link>
					</li>
					<li className={`${projectCampaign.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/project-campaigns" className="ai-icon">
							<i className="flaticon-381-archive"></i>
							<span className="nav-text">Project Campaigns</span>
						</Link>
					</li>
					<li className={`${tokenSaleCampaignGroup.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/token-sale-campaign-group" className="ai-icon">
							<i className="flaticon-381-transfer"></i>
							<span className="nav-text">Token Sale Campaigns</span>
						</Link>
					</li>
					<li className={`${diamondCircle.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/diamond-circle" className="ai-icon">
							<i className="flaticon-003-diamond"></i>
							<span className="nav-text">Diamond Circle</span>
						</Link>
					</li>
					<li className={`${lockedTRS.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/locked-trs" className="ai-icon">
							<i className="flaticon-381-lock-3"></i>
							<span className="nav-text">Locked TRS</span>
						</Link>
					</li>
					<li className={`${vestingWallets.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/vesting-wallets" className="ai-icon">
							<i className="flaticon-163-calendar"></i>
							<span className="nav-text">Vesting Wallets</span>
						</Link>
					</li>
					<li className={`${ingxRestriction.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/ingx-restriction" className="ai-icon">
							<i className="flaticon-018-remove-user"></i>
							<span className="nav-text">IngX Restriction</span>
						</Link>
					</li>
					<li className={`${troubleshoot.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/troubleshoot" className="ai-icon">
							<i className="flaticon-381-settings-9"></i>
							<span className="nav-text">Troubleshoot</span>
						</Link>
					</li>
					<li className={`${support.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/support" className="ai-icon">
							<i className="flaticon-381-like"></i>
							<span className="nav-text">Token Support</span>
						</Link>
					</li>
					<li className={`${rewardUnlock.includes(path) ? 'mm-active' : ''}`}>
						<Link to="/rewardUnlock" className="ai-icon">
							<i className="flaticon-381-lock-3"></i>
							<span className="nav-text">Reward Unlock</span>
						</Link>
					</li>
					<li className={`${emails.includes(path) ? 'mm-active' : ''}`}>
						<Link className="has-arrow ai-icon" to="#">
							<i className="flaticon-381-send-1"></i>
							<span className="nav-text">Emails</span>
						</Link>
						<ul>
							<li>
								<Link
									className={`${path === 'email-digest' ? 'mm-active' : ''}`}
									to="/email-digest"
								>
									Digest Emails
								</Link>
							</li>
							<li>
								<Link
									className={`${path === 'email-triggered' ? 'mm-active' : ''}`}
									to="/email-triggered"
								>
									Triggered Emails
								</Link>
							</li>
						</ul>
					</li>
				</MM>
				<div className="copyright">
					<p>
						<strong>Film.io Admin Dashboard</strong> © 2022 All Rights Reserved
					</p>
				</div>
			</PerfectScrollbar>
		</div>
	)
}

export default SideBar
