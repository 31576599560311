import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import { editFanAction, getFanActionById } from 'services/SuperfanService'
import _ from 'lodash'

const useFanActionForm = () => {
	const history = useHistory()
	const { id } = useParams()
	const [data, setData] = useState({
		name: '',
		points: 0,
		limit: null,
		limitDurationDays: null,
		minimalLimit: null,
	})

	const afterFetchAction = (data, error) => {
		if (!error) {
			setData(data)
		}
	}
	const [{ loading: loadingAction }] = useAxios(getFanActionById(id, !_.isNil(id)), afterFetchAction)

	const service = editFanAction(data)
	const afterSave = (_data, error) => {
		if (!error) {
			const message = 'Action updated successfully!'
			showMessage({ message })
			history.push('/superfan-action')
		}
	}
	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (payload) => {
		if (payload && Object.keys(payload).length > 0) {
			Object.keys(payload).forEach(key => {
				if (payload[key] === "") {
					payload[key] = null
				}
			})
		}
		setData({
			...data,
			...payload,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const handleSave = () => {
		saveChanges()
	}

	const title = _.isNil(id) ? 'Superfan Action Details' : 'Edit Superfan Action'

	const loading = saving || loadingAction || saving

	return {
		data,
		title,
		loading,
		handleSave,
		handleCancel,
		handleChange
	}
}

export default useFanActionForm
