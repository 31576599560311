import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import { addFanLevel, editFanLevel, getFanLevelById } from 'services/SuperfanService'
import _ from 'lodash'

const useFanLevelForm = () => {
	const history = useHistory()
	const { id } = useParams()
	const [data, setData] = useState({
		name: '',
		barColor: '',
		image: '',
		active: true,
		description: ''
	})


	const afterFetchLevel = (data, error) => {
		if (!error) {
			data.active = Boolean(data.active)
			setData(data)
		}
	}
	const [{ loading: loadingLevel }] = useAxios(getFanLevelById(id, !_.isNil(id)), afterFetchLevel)

	const service = _.isNil(id) ? addFanLevel(data) : editFanLevel(data)
	const afterSave = (_data, error) => {
		if (!error) {
			const message = 'Level updated successfully!'
			showMessage({ message })
			history.push('/superfan-level')
		}
	}
	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (payload) => {
		setData({
			...data,
			...payload,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const handleSave = () => {
		saveChanges()
	}

	const title = _.isNil(id) ? 'Superfan Milestone Details' : 'Edit Superfan Milestone'

	const loading = saving || loadingLevel || saving

	return {
		data,
		title,
		loading,
		handleSave,
		handleCancel,
		handleChange
	}
}

export default useFanLevelForm
