import { Button } from 'react-bootstrap'
import useDaoTierForm from './useDaoTierForm'
import { LoadMask } from 'components/common/loadMask'
import { UploadFile } from 'components/common/uploadFile'
import { CustomCheckbox } from 'components/common/customCheckbox'

const DaoTierForm = () => {
	const {
		data,
		title,
		loading,
		handleSave,
		handleCancel,
		handleChange,
		handleAddNewBenefit,
		handleChangeBenefit,
		handleRemoveBenefit
	} = useDaoTierForm()

	const imageValue = typeof data.image === 'string' ? data.image : ''
	const ringImageValue = typeof data.ringImage === 'string' ? data.ringImage : ''
	const levelUpImageValue = typeof data.levelUpImage === 'string' ? data.levelUpImage : ''

	const renderBenefits = () => {
		return data?.benefits?.map((benefit, idx) => {
			return (
				<div
					className="form-row"
					key={`benefit-${idx}`}
					style={{
						borderTop: '1px solid #473F72',
						paddingTop: '20px',
					}}
				>
					<div className="form-group col-md-9">
						<label>Description</label>
						<input
							value={benefit.description}
							type="text"
							className="form-control"
							placeholder="Description"
							onChange={(e) => {
								handleChangeBenefit({ description: e.target.value }, idx)
							}}
						/>
					</div>
					<div className="form-group col-md-2">
						<CustomCheckbox
							label="Airdrop Benefit"
							checked={benefit.highlight}
							setChecked={(checked) => {
								handleChangeBenefit({ highlight: checked }, idx)
							}}
						/>
					</div>
					<div
						className="col-md-1"
						style={{
							display: 'flex',
							alignItems: 'end',
							marginBottom: '20px',
						}}
					>
						<Button className="mr-2" variant="danger" onClick={handleRemoveBenefit(idx)}>
							<i className="fa fa-trash"></i>
						</Button>
					</div>
				</div>
			)
		})
	}

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">{title}</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-6">
												<label>Name</label>
												<input
													value={data.name}
													type="text"
													className="form-control"
													placeholder="Tier Name"
													onChange={(e) => {
														handleChange({ name: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<label>Token Bonus</label>
												<input
													value={data.bonus}
													type="number"
													className="form-control"
													placeholder="Token Bonus"
													onChange={(e) => {
														handleChange({ bonus: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<label>Airdrop Percent</label>
												<input
													value={data.airdropPercent}
													type="number"
													className="form-control"
													placeholder="Airdrop Percent"
													onChange={(e) => {
														handleChange({ airdropPercent: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<label>Order</label>
												<input
													value={data.order}
													type="number"
													className="form-control"
													placeholder="Order"
													onChange={(e) => {
														handleChange({ order: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Stake Limit</label>
												<input
													value={data.stakeLimit}
													type="number"
													className="form-control"
													placeholder="Stake Limit"
													onChange={(e) => {
														handleChange({ stakeLimit: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-3">
												<label>Points Threshold</label>
												<input
													value={data.pointsThreshold}
													type="number"
													className="form-control"
													placeholder="Points Threshold"
													onChange={(e) => {
														handleChange({ pointsThreshold: Number(e.target.value) })
													}}
												/>
											</div>
											<div className="input-group mb-3 col-md-3">
												<label>Image</label>
												<UploadFile
													variant="button"
													defaultImage={imageValue}
													defaultValue={imageValue}
													setFile={(image) => {
														handleChange({ image })
													}}
												/>
											</div>
											<div className="input-group mb-3 col-md-3">
												<label>Ring Image</label>
												<UploadFile
													variant="button"
													defaultImage={ringImageValue}
													defaultValue={ringImageValue}
													setFile={(ringImage) => {
														handleChange({ ringImage })
													}}
												/>
											</div>
											<div className="input-group mb-3 col-md-3">
												<label>Level Up Image</label>
												<UploadFile
													variant="button"
													defaultImage={levelUpImageValue}
													defaultValue={levelUpImageValue}
													setFile={(levelUpImage) => {
														handleChange({ levelUpImage })
													}}
												/>
											</div>
											<div className="form-group col-md-12">
												<label>Description</label>
												<textarea
													value={data.description}
													className="form-control"
													rows="4"
													placeholder="Description"
													onChange={(e) => {
														handleChange({ description: e.target.value })
													}}
												/>
											</div>
										</div>
										{renderBenefits()}
										<div className="form-row mb-3">
											<Button className="mr-2" variant="info" onClick={handleAddNewBenefit}>
												Add New Benefit
											</Button>
										</div>
										<Button className="mr-2" variant="success" onClick={handleSave}>
											Save
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default DaoTierForm
