import useAxios from 'hooks/useAxios'
import { useState } from 'react'
import { getFanLevels } from 'services/SuperfanService'

const useFanLevelTable = () => {
	const [levels, setLevels] = useState([])

	const afterFetchLevels = (data, error) => {
		if (!error) {
			setLevels(data)
		}
	}

	const [{ loading }] = useAxios(getFanLevels(), afterFetchLevels)

	return {
		loading,
		levels
	}
}

export default useFanLevelTable
