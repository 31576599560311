import _ from 'lodash'
import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import { addDaoTier, editDaoTier, getDaoTierById } from 'services/DaoTierService'

const useDaoTierForm = () => {
	const history = useHistory()
	const { id } = useParams()
	const [data, setData] = useState({
		name: '',
		description: '',
		image: '',
		ringImage: '',
		levelUpImage: '',
		bonus: '',
		benefits: [],
		airdropPercent: '',
		order: '',
		stakeLimit: ''
	})

	const afterFetchLevel = (data, error) => {
		if (!error) {
			data.active = Boolean(data.active)
			setData(data)
		}
	}
	const [{ loading: loadingLevel }] = useAxios(getDaoTierById(id, !_.isNil(id)), afterFetchLevel)

	const service = _.isNil(id) ? addDaoTier(data) : editDaoTier(data)
	const afterSave = (_data, error) => {
		if (!error) {
			const message = `DAO Tier ${_.isNil(id) ? 'created' : 'updated'} successfully!`
			showMessage({ message })
			history.push('/dao-tier')
		}
	}
	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (payload) => {
		setData({
			...data,
			...payload,
		})
	}

	const handleChangeBenefit = (payload, idx) => {
		data.benefits[idx] = {
			...data.benefits[idx],
			...payload,
		}
		setData({
			...data,
		})
	}

	const handleCancel = () => {
		history.goBack()
	}

	const handleAddNewBenefit = () => {
		setData({
			...data,
			benefits: [
				...data.benefits,
				{
					tierId: data.id,
				},
			],
		})
	}

	const handleRemoveBenefit = (idx) => () => {
		const benefits = data.benefits.filter((_el, pos) => {
			return pos !== idx
		})
		setData({
			...data,
			benefits
		})
	}

	const handleSave = () => {
		saveChanges()
	}

	const title = _.isNil(id) ? 'DAO Tier Details' : 'Edit DAO Tier'

	const loading = saving || loadingLevel || saving

	return {
		data,
		title,
		loading,
		handleSave,
		handleCancel,
		handleChange,
		handleChangeBenefit,
		handleAddNewBenefit,
		handleRemoveBenefit
	}
}

export default useDaoTierForm
