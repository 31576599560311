import { Link } from 'react-router-dom'
import { Row, Col, Card, Table, Button } from 'react-bootstrap'
import PageTitle from 'jsx/layouts/PageTitle'
import useDaoTierTable from './useDaoTierTable'
import { LoadMask } from 'components/common/loadMask'
import { ConfirmModal } from 'components/common/confirmModal'

const DaoTierTable = () => {
	const {
		tiers,
		isOpen,
		loading,
		tierToDelete,
		handleDeleteTier,
		handleConfirm,
		handleClose,
	} = useDaoTierTable()

	const renderDaoTiers = () => {
		return tiers.map((tier) => {
			return (
				<tr key={`tier-${tier.id}`}>
					<td>
						<div className="d-flex align-items-center">
							<img src={tier.image} className="rounded-lg mr-2" width="24" alt="" />{' '}
						</div>
					</td>
					<td>
						<div className="d-flex align-items-center">
							<img src={tier.ringImage} className="rounded-lg mr-2" width="24" alt="" />{' '}
						</div>
					</td>
					<td>
						<div className="d-flex align-items-center">
							<img src={tier.levelUpImage} className="rounded-lg mr-2" width="24" alt="" />{' '}
						</div>
					</td>
					<td>{tier.name}</td>
					<td>{tier.bonus}</td>
					<td>{tier.pointsThreshold}</td>
					<td>{tier.airdropPercent && `${tier.airdropPercent}%`}</td>
					<td>{tier.stakeLimit}</td>
					<td>{tier.order}</td>
					<td>
						<div className="d-flex">
							<Link
								to={`/edit-dao-tier/${tier.id}`}
								className="btn btn-primary shadow btn-xs sharp mr-1"
							>
								<i className="fa fa-pencil"></i>
							</Link>
							<Link
								className="btn btn-danger shadow btn-xs sharp mr-1"
								onClick={handleDeleteTier(tier.id, tier.name)}
							>
								<i className="fa fa-trash"></i>
							</Link>
						</div>
					</td>
				</tr>
			)
		})
	}

	const text = `Are you sure you want to delete the ${tierToDelete?.tierName} Tier`
	return (
		<>
			<ConfirmModal {...{ isOpen, handleClose, text, handleConfirm }} />
			<PageTitle title={'DAO Tiers'} />
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current DAO Tiers</Card.Title>
							<Link to="new-dao-tier">
								<Button className="mr-2" variant="success">
									Create Tier
								</Button>
							</Link>
						</Card.Header>
						<Card.Body>
							{loading ? (
								<LoadMask />
							) : (
								<Table responsive>
									<thead>
										<tr>
											<th>
												<strong>Image</strong>
											</th>
											<th>
												<strong>Ring Image</strong>
											</th>
											<th>
												<strong>Level Up Image</strong>
											</th>
											<th>
												<strong>Name</strong>
											</th>
											<th>
												<strong>Token Bonus</strong>
											</th>
											<th>
												<strong>Points Threshold</strong>
											</th>
											<th>
												<strong>Airdrop %</strong>
											</th>
											<th>
												<strong>Stake Limit</strong>
											</th>
											<th>
												<strong>Order</strong>
											</th>
											<th>
												<strong>Action</strong>
											</th>
										</tr>
									</thead>
									<tbody>{renderDaoTiers()}</tbody>
								</Table>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default DaoTierTable
