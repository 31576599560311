import { Link } from 'react-router-dom'
import { Row, Col, Card, Table, Button } from 'react-bootstrap'
import PageTitle from 'jsx/layouts/PageTitle'
import useFanLevelTable from './useFanLevelTable'
import { LoadMask } from 'components/common/loadMask'

const FanLevelTable = () => {
	const { levels, loading } = useFanLevelTable()

	const renderStages = () => {
		return levels.map((level) => {
			return (
				<tr key={`stage-${level.id}`}>
					<td>
						<div className="d-flex align-items-center">
							<img src={level.image} className="rounded-lg mr-2" width="24" alt="" />{' '}
						</div>
					</td>
					<td>{level.name}</td>
					<td>{level.bonus}%</td>
					<td>{level.pointsThreshold} pts</td>
					<td>{level.description}</td>
					<td>
						<div
							style={{
								backgroundColor: level.barColor,
								width: '50%',
								height: '15px',
								borderRadius: '6px',
							}}
						></div>
					</td>
					<td>
						<div className="d-flex">
							<Link
								to={`/edit-superfan-level/${level.id}`}
								className="btn btn-primary shadow btn-xs sharp mr-1"
							>
								<i className="fa fa-pencil"></i>
							</Link>
						</div>
					</td>
				</tr>
			)
		})
	}

	return (
		<>
			<PageTitle title={'Superfan Milestones'} />
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Superfan Milestones</Card.Title>
							<Link to="new-superfan-level">
								<Button className="mr-2" variant="success">
									Create Level
								</Button>
							</Link>
						</Card.Header>
						<Card.Body>
							{loading ? (
								<LoadMask />
							) : (
								<Table responsive>
									<thead>
										<tr>
											<th>
												<strong>Image</strong>
											</th>
											<th>
												<strong>Name</strong>
											</th>
											<th>
												<strong>Bonus</strong>
											</th>
											<th>
												<strong>Points Threshold</strong>
											</th>
											<th>
												<strong>Description</strong>
											</th>
											<th>
												<strong>Color</strong>
											</th>
											<th>
												<strong>Action</strong>
											</th>
										</tr>
									</thead>
									<tbody>{renderStages()}</tbody>
								</Table>
							)}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</>
	)
}

export default FanLevelTable
