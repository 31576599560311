import useAxios from 'hooks/useAxios'
import { useState } from 'react'
import { getFanActions } from 'services/SuperfanService'

const useFanActionTable = () => {
	const [actions, setActions] = useState([])

	const afterFetchActions = (data, error) => {
		if (!error) {
			setActions(data)
		}
	}

	const [{ loading }] = useAxios(getFanActions(), afterFetchActions)

	return {
		loading,
		actions
	}
}

export default useFanActionTable
