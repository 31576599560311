import { Button } from 'react-bootstrap'
import useFanLevelForm from './useFanLevelForm'
import { LoadMask } from 'components/common/loadMask'
import { UploadFile } from 'components/common/uploadFile'
import { CustomCheckbox } from 'components/common/customCheckbox'

const FanLevelForm = () => {
	const {
		data,
		title,
		loading,
		handleSave,
		handleCancel,
		handleChange,
	} = useFanLevelForm()

	const uploadFileValue = typeof data.image === 'string' ? data.image : ''

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">{title}</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-8">
												<label>Name</label>
												<input
													value={data.name}
													type="text"
													className="form-control"
													placeholder="Level Name"
													onChange={(e) => {
														handleChange({ name: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Bonus</label>
												<input
													value={data.bonus}
													type="number"
													className="form-control"
													placeholder="Level Bonus"
													onChange={(e) => {
														handleChange({ bonus: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-8">
												<label>Bar Color</label>
												<input
													value={data.barColor}
													type="text"
													className="form-control"
													placeholder="Color"
													onChange={(e) => {
														handleChange({ barColor: e.target.value })
													}}
												/>
											</div>
											<div className="input-group mb-3 col-md-2">
												<label>Image</label>
												<UploadFile
													variant="button"
													defaultImage={uploadFileValue}
													defaultValue={uploadFileValue}
													setFile={(image) => {
														handleChange({ image })
													}}
													id="superfanEdit"
												/>
											</div>
											<div className="form-group col-md-2">
												<CustomCheckbox
													label="Active"
													checked={data.active}
													setChecked={(checked) => {
														handleChange({ active: checked })
													}}
												/>
											</div>
											<div className="form-group col-md-12">
												<label>Description</label>
												<textarea
													value={data.description}
													className="form-control"
													rows="4"
													placeholder="Description"
													onChange={(e) => {
														handleChange({ description: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Points Threshold</label>
												<input
													value={data.pointsThreshold}
													type="number"
													className="form-control"
													placeholder="Points Threshold"
													onChange={(e) => {
														handleChange({ pointsThreshold: Number(e.target.value) })
													}}
												/>
											</div>
										</div>
										<div className="form-row mb-3">
										</div>
										<Button className="mr-2" variant="success" onClick={handleSave}>
											Save
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default FanLevelForm
