const buildFormFanLevelData = (data, edit) => {
	const formData = new FormData()
	const fields = ['name', 'description', 'image', 'active', 'barColor', 'pointsThreshold', 'bonus']
	if (edit) fields.push('id')

	const files = ['image']
	const objects = ['metrics']

	fields.forEach((key) => {
		if (data[key] instanceof File) {
			formData.append(key, data[key], data[key].name)
		} else if (objects.includes(key)) {
			formData.append(key, JSON.stringify(data[key]))
		} else if (!files.includes(key) && !objects.includes(key)) {
			formData.append(key, data[key])
		}
	})
	return formData
}

export const addFanLevel = (data) => {
	const formData = buildFormFanLevelData(data)
	return {
		method: 'post',
		url: '/superfan/level',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}

export const editFanLevel = (data) => {
	const formData = buildFormFanLevelData(data, true)
	return {
		method: 'put',
		url: '/superfan/level',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}

export const getFanLevelById = (id, autoexec = true) => ({
	method: 'get',
	url: `/superfan/level/${id}`,
	autoexec,
})

export const getFanActionById = (id, autoexec = true) => ({
	method: 'get',
	url: `/superfan/action/${id}`,
	autoexec,
})

export const getStudioById = (autoexec, id) => ({
	method: 'get',
	url: `/studio/${id}`,
	autoexec,
})

export const getFanLevels = () => ({
	method: 'get',
	url: '/superfan/levels',
	autoexec: true,
})

export const updateAllUsersFanLevel = () => ({
	method: 'post',
	url: '/userFanLevel/updateAllUsers',
	autoexec: false,
})

export const getFanActions = () => ({
	method: 'get',
	url: '/superfan/actions',
	autoexec: true,
})

export const editFanAction = (data) => ({
	method: 'put',
	url: '/superfan/action',
	data,
	autoexec: false,
})
