import { Button } from 'react-bootstrap'
import useFanActionForm from './useFanActionForm'
import { LoadMask } from 'components/common/loadMask'

const FanActionForm = () => {
	const {
		data,
		title,
		loading,
		handleSave,
		handleCancel,
		handleChange,
	} = useFanActionForm()

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">{title}</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-8">
												<label>Name</label>
												<input
													value={data.name}
													type="text"
													className="form-control"
													placeholder="Action Name"
													onChange={(e) => {
														handleChange({ name: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Points</label>
												<input
													value={data.points}
													type="number"
													className="form-control"
													placeholder="Points"
													onChange={(e) => {
														handleChange({ points: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-8">
												<label>Limit</label>
												<input
													value={data.limit}
													type="number"
													className="form-control"
													placeholder="Limit"
													onChange={(e) => {
														handleChange({ limit: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-8">
												<label>Limit Duration</label>
												<input
													value={data.limitDurationDays}
													type="number"
													className="form-control"
													placeholder="Limit Duration"
													onChange={(e) => {
														handleChange({ limitDurationDays: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-8">
												<label>Minimal Limit</label>
												<input
													value={data.minimalLimit}
													type="number"
													className="form-control"
													placeholder="Minimal Limit"
													onChange={(e) => {
														handleChange({ minimalLimit: e.target.value })
													}}
												/>
											</div>
										</div>
										<div className="form-row mb-3">
										</div>
										<Button className="mr-2" variant="success" onClick={handleSave}>
											Save
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default FanActionForm
